import { useState } from 'react';

export default function ConfigItem({ name, initialValue, onChange, className = '' }) {
    const [value, setValue] = useState(initialValue || false);

    return (
        <div className={`flex ${className} items-center`}>
            <div
                className="w-4 h-4 rounded-[50%] bg-white shrink-0 flex justify-center items-center cursor-pointer"
                onClick={() => {
                    const checked = !value;
                    setValue(checked);
                    onChange(checked);
                }}
            >
                <div
                    className={`w-3 h-3 rounded-[50%] shrink-0 ${
                        value ? 'bg-[#8504b8]' : 'bg-white'
                    }`}
                />
            </div>
            <span className="ml-1">{name}</span>
        </div>
    );
}
