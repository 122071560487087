import React from 'react';
import ReactDOM from 'react-dom/client';
import DeobfuscationPage from './pages/Deobfuscation';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Inter" />
        <DeobfuscationPage />
    </React.StrictMode>
);
