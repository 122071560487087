import { motion } from 'framer-motion';

const styles = {
    primary:
        'bg-[#531ACD] text-white shadow-[0px_0px_0px_1px_#360E8B,_0px_4px_8px_0px_rgba(22,_36,_44,_0.16),_0px_8px_10px_-7px_rgba(22,_36,_44,_0.16),_0px_-2px_0.5px_0px_#3A1194_inset,_0px_1px_2px_0px_rgba(255,_255,_255,_0.10)_inset]',
    secondary:
        'bg-[#5b65ea] text-white shadow-[0px_0px_0px_1px_#3942b8,_0px_4px_8px_0px_rgba(22,_36,_44,_0.16),_0px_8px_10px_-7px_rgba(22,_36,_44,_0.16),_0px_-2px_0.5px_0px_#3942b8_inset,_0px_1px_2px_0px_rgba(255,_255,_255,_0.10)_inset]'
};

const hoverColors = {
    primary: '#6228de',
    secondary: '#6771f0'
};

export default function Button({ variant, className, children, ...props }) {
    return (
        <motion.button
            className={`flex items-center justify-center ${styles[variant]} rounded-full text-sm cursor-pointer ${className}`}
            whileHover={{ scale: 1.03, backgroundColor: hoverColors[variant] }}
            whileTap={{ scale: 0.95 }}
            {...props}
        >
            {children}
        </motion.button>
    );
}
